import React, { useEffect, useState } from "react";
import DeletedIcon from "../../../../assests/images/icon3.svg";
import AwaitingIcon from "../../../../assests/images/icon4.svg";
import OrderHistoryIcon from "../../../../assests/images/icon5.svg";
import ManageTeamIcon from "../../../../assests/images/manage-team.png";
import RefundOrderIcon from "../../../../assests/img/refund-order-new.png";
import GateWayIcon from "../../../../assests/images/icon8.svg";
import ChangePasswordIcon from "../../../../assests/images/icon10.svg";
import LogoutIcon from "../../../../assests/images/icon11.svg";
import { StyledSidebar, StyledAdminMenu } from "./style";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/authSlice";
import {
  cleanOrdersList,
  getAllFolderCount,
  toggleSidebar,
} from "../../../../redux/orderSlice";
import { FaTimes } from "react-icons/fa";
import jwt from "jwt-decode";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const count = useSelector((state) => state?.order?.count);
  const showSideBar = useSelector((state) => state?.order?.showSideBar);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  let userType;
  let userName;
  if (token !== null) {
    const decodedToken = jwt(token);
    userType = decodedToken?.user?.type;
    userName = decodedToken?.user?.name;
  }
  useEffect(() => {
    if (token) {
      dispatch(getAllFolderCount(userName));
      dispatch(cleanOrdersList());
    }
  }, [location?.pathname]);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    dispatch(logout());
  };
  

  return (
    <StyledSidebar>
      <div className={showSideBar ? "sidebar open" : "sidebar"}>
        <StyledAdminMenu>
          <button
            type="button"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
            className="close-menu"
          >
            <FaTimes />
          </button>
          <ul>
            <li className={isActive === "awaiting" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/awaiting-order"
                onClick={() => setIsActive("awaiting")}
              >
                <span className="side-icon">
                  <img src={AwaitingIcon} alt="AwaitingIcon" />
                </span>
                Awaiting Response{" "}
                <span className="count">{count?.awaitingOrdersCount}</span>
              </NavLink>
            </li>

            <li className={isActive === "awaitingGovt" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/awaiting-govt-order"
                onClick={() => setIsActive("awaitingGovt")}
              >
                <span className="side-icon">
                  <img src={AwaitingIcon} alt="AwaitingIcon" />
                </span>
                Awaiting Govt{" "}
                <span className="count">{count?.awaitingGovtCount}</span>
              </NavLink>
            </li>

            <li className={isActive === "customer" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/customer-called"
                onClick={() => setIsActive("customer")}
              >
                <span className="side-icon">
                  <img src={AwaitingIcon} alt="AwaitingIcon" />
                </span>
                Customer Called{" "}
                <span className="count">
                  {count?.contactCustomerCalledOrdersCount}
                </span>
              </NavLink>
            </li>
            <li className={isActive === 'customercontact' ? 'active' : null}>
                            <NavLink to='/admin/contact-customer' onClick={() => setIsActive('customercontact')}>
                                <span className='side-icon'>
                                    <img src={AwaitingIcon} alt='AwaitingIcon' />
                                </span>
                            Contact Customer  <span className='count'>{count?.contactCustomerOrdersCount}</span>
                            </NavLink>
                        </li>

            <li className={isActive === "allorderhistory" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/all-order"
                onClick={() => setIsActive("allorderhistory")}
              >
                <span className="side-icon">
                  <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                </span>
                Order History{" "}
                <span className="count">{count?.allOrdersCount}</span>
              </NavLink>
            </li>
            <li className={isActive === "archive" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/archive-order"
                onClick={() => setIsActive("archive")}
              >
                <span className="side-icon">
                  <img src={DeletedIcon} alt="DeletedIcon" />
                </span>
                Deleted Orders{" "}
                <span className="count">{count?.deletedOrdersCount}</span>
              </NavLink>
            </li>

            <li className={isActive === "refund" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/refund-order"
                onClick={() => setIsActive("refund")}
              >
                <span className="side-icon">
                  <img src={RefundOrderIcon} alt="RefundOrderIcon" />
                </span>
                Refund Orders{" "}
                <span className="count">{count?.refundOrdersCount}</span>
              </NavLink>
            </li>

            <li className={isActive === "change-password" ? "active" : null}>
              <NavLink
                to="/admin/change-password"
                onClick={() => setIsActive("change-password")}
              >
                <span className="side-icon">
                  <img src={ChangePasswordIcon} alt="ChangePasswordIcon" />
                </span>
                Change Password{" "}
              </NavLink>
            </li>
            <li className={isActive === "setting" ? "active" : null}>
              <NavLink
                to="/admin/setting"
                onClick={() => setIsActive("setting")}
              >
                <span className="side-icon">
                  <img src={GateWayIcon} alt="GateWayIcon" />
                </span>
                Gateway
              </NavLink>
            </li>
            <li className={isActive === "manage-team" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/manage-team"
                onClick={() => setIsActive("manage-team")}
              >
                <span className="side-icon">
                  <img src={ManageTeamIcon} alt="ManageTeamIcon" />
                </span>
                Manage Team
              </NavLink>
            </li>
            {(userType == "Admin") && (
                             <li className={isActive === 'countryOrderCountList' ? 'active' : null}>
                             <NavLink to='/admin/processed-order' onClick={() => setIsActive('countryOrderCountList')}>
                                 <span className='side-icon'>
                                     <img src={OrderHistoryIcon} alt='NewOrderIcon' />
                                 </span>
                                  Order Processed {" "}
                             </NavLink>
                         </li>
                        )}
            <li>
              <NavLink to="/logout" onClick={handleLogout}>
                <span className="side-icon">
                  <img src={LogoutIcon} alt="LogoutIcon" />
                </span>
                Logout
              </NavLink>
            </li>
          </ul>
        </StyledAdminMenu>
      </div>
    </StyledSidebar>
  );
};

export default AdminSidebar;
